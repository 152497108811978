import React from "react";
import { Router } from "@reach/router";

import EmailVerification from "./email-verification";
import PasswordRecovery from "./password-recovery";

const App = () => {
  return (
    <Router basepath="/app">
      <EmailVerification path="/email-verification/" />
      <PasswordRecovery path="/password-recovery/" />
    </Router>
  );
};
export default App;
